import { useLayoutEffect } from "react";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import useDOMTextSearcher from "features/common/DOMTextSearcher/DOMTextSearcher";
import FaqNo from "./FAQ_NO";
import FaqEn from "./FAQ_EN";
import "./Faq.scss";
import { useIntl } from "locale/langUtils";

export default function FaqPage(): JSX.Element {
  const intl = useIntl();

  const { SearchFieldComponent, registerRefNodeToSearchIn, cleanUp } = useDOMTextSearcher();

  useLayoutEffect(() => {
    cleanUp();
  }, [cleanUp]);

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "faq.meta.title" }),
        titleDescription: intl.formatMessage({ id: "faq.meta.title.description" }),
        description: intl.formatMessage({ id: "faq.meta.description" })
      }}
      className="relative"
    >
      {{
        heading: {
          pageTitle: <>{intl.locale === "nb" ? "Ofte stilte spørsmål" : "Frequently Asked Questions"}</>
        },
        pageContent: (
          <Section className="relative">
            <SearchFieldComponent
              ariaLabel={intl.formatMessage({ id: "faq.search.arialabel" })}
              ariaPrev={intl.formatMessage({ id: "faq.search.prev" })}
              ariaNext={intl.formatMessage({ id: "faq.search.next" })}
              className="searchfield-wrapper--faq sticky"
            />
            <div className="faq-content">
              {intl.locale === "nb" ? (
                <FaqNo ref={registerRefNodeToSearchIn} />
              ) : (
                <FaqEn ref={registerRefNodeToSearchIn} />
              )}
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
